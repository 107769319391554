<script setup lang="ts">
    import { ref, onMounted, onUnmounted } from 'vue';
    import { useModal, ModalsContainer } from 'vue-final-modal';
    import ModalConfirm from './ModalWrapper.vue';
    import { useI18n } from 'vue-i18n';
    const { t } = useI18n();

    // Создаем модальное окно с использованием `vue-final-modal`
    const { open, close } = useModal({
        component: ModalConfirm,
        attrs: {
            title: t('modals.first.title'),
            bonus: t('modals.first.bonus'),
            button: t('modals.first.button_text'),
            spins: 1,
            onConfirm() {
                close();
                localStorage.removeItem('currentSpin');
                gtag("event", "popup_freespin_click");
            },
        },
    });

    // Создаем реактивную переменную для хранения значения флага
    const localStorageFlag = ref(localStorage.getItem('currentSpin'));

    // Функция для проверки значения флага и открытия модального окна
    function checkFlag() {
        if (localStorageFlag.value === 'first') {
            open();
        }
    }

    // Проверяем значение флага через интервал
    let intervalId: number;

    onMounted(() => {
        intervalId = window.setInterval(() => {
            localStorageFlag.value = localStorage.getItem('currentSpin');
            checkFlag();
        }, 1); // Проверяем каждые 1 секунду
    });

    onUnmounted(() => {
        window.clearInterval(intervalId); // Очищаем интервал при размонтировании
    });
</script>

<template>
    <ModalsContainer />
</template>
