<script setup>
    import { ref, onMounted, onUnmounted, watch } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { changeLocale } from '@/js/i18nController';

    const { locale, availableLocales } = useI18n();

    const isOpen = ref(false); // Состояние открытого/закрытого меню

    // Функция для открытия/закрытия меню
    const openDropdown = () => {
        isOpen.value = !isOpen.value;
        gtag("event", "language_call");
    };

    // Функция для изменения выбранного языка
    const changeLang = (lang) => {
        gtag("event", "change_language", { language_id: lang });
        changeLocale(lang);
        locale.value = lang;
        isOpen.value = false; // Закрываем меню после выбора языка
    };

    // Обработчик для закрытия меню при клике вне его области
    const closeDropdown = (event) => {
        if (!event.target.closest('.dropdown')) {
            isOpen.value = false;
        }
    };

    // Добавляем обработчик события при монтировании компонента
    onMounted(() => {
        document.addEventListener('click', closeDropdown);
    });

    // Удаляем обработчик события при размонтировании компонента
    onUnmounted(() => {
        document.removeEventListener('click', closeDropdown);
    });

    // Слежение за изменением locale и вызов loadLocalization
    watch(locale, (newLocale) => {
        localStorage.setItem('preferred_locale', newLocale); // Обновление значения в localStorage
    });
</script>

<template>
    <div
        class="dropdown js-dropdown js-lang open text_semibold-18"
        data-dropdown-type="closing"
    >
        <button
            class="dropdown__button js-dropdown-toggler js-lang-display"
            :class="{ 'dropdown__button-open': isOpen }"
            @click="openDropdown"
        >
            <div
                class="button__wrap"
                :class="`button__wrap-${locale}`"
            >
                <img
                    :src="`/img/flags/${locale}.svg`"
                    :alt="locale"
                />
                <div class="button__text text-semibold-18">
                    {{ locale.toUpperCase() }}
                </div>
                <img
                    :src="`/img/arrow-down.svg`"
                    alt="arrow"
                    class="button__arrow"
                    :class="{ 'button__arrow-open': isOpen }"
                />
            </div>
        </button>
        <div
            class="dropdown__box js-dropdown-box"
            v-show="isOpen"
        >
            <ul>
                <li
                    v-for="(lang, index) in availableLocales"
                    :key="index"
                >
                    <template v-if="locale !== lang">
                        <a
                            class="js-lang-toggler"
                            :class="{ ar: locale === 'ar' }"
                            @click="changeLang(lang)"
                        >
                            <img
                                :src="`/img/flags/${lang}.svg`"
                                :alt="lang"
                            />
                            <div class="button__text text-semibold-18">
                                {{ lang }}
                            </div>
                        </a>
                    </template>
                </li>
            </ul>
        </div>
    </div>
</template>

<style scoped>
    @import './LanguageSwitcher.scss';
</style>
